/**
 * icon types = 'default', 'rounded', 'outline', 'sharp'
 *
 */
module.exports = {
  theme: 'clockwork',
  config: {
    show_utility_bar: false
  },
  icons: {
    type: 'rounded',
    custom: {
      ic_search: {
        viewbox: '0 0 35 35',
        content: '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none"><g id="search"><path id="Vector" d="M26.2583 23.7984H24.9417L24.475 23.3484C26.1083 21.4484 27.0917 18.9818 27.0917 16.2984C27.0917 10.3151 22.2417 5.46509 16.2583 5.46509C10.275 5.46509 5.42499 10.3151 5.42499 16.2984C5.42499 22.2818 10.275 27.1318 16.2583 27.1318C18.9417 27.1318 21.4083 26.1484 23.3083 24.5151L23.7583 24.9818V26.2984L32.0917 34.6151L34.575 32.1318L26.2583 23.7984ZM16.2583 23.7984C12.1083 23.7984 8.75832 20.4484 8.75832 16.2984C8.75832 12.1484 12.1083 8.79842 16.2583 8.79842C20.4083 8.79842 23.7583 12.1484 23.7583 16.2984C23.7583 20.4484 20.4083 23.7984 16.2583 23.7984Z" fill="#425563"/></g></svg>'
      },
      ic_arrow_forward: {
        viewbox: '0 0 39 16',
        content: '<g fill="none" fill-rule="evenodd"><path d="M3-4h24v24H3z"/><path fill="currentColor" d="M31 0l-1.41 1.41L35.17 7H0v2h35.17l-5.58 5.59L31 16l8-8z"/></g>'
      }
    }
  }
}
